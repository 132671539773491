import { NavLink, useNavigate } from "react-router-dom";
import diagonalRightArrowIconWhite from "../svg/diagonalRightArrowIconWhite.svg";
import { useState, useEffect } from "react";
import axios from 'axios';

function SignUp() {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    const navigate = useNavigate();
    const [placeHolderMover, setPlaceHolderMover] = useState(
        [
            { name: 'firstName', status: false },
            { name: 'lastName', status: false },
            { name: 'email', status: false },
            { name: 'phoneNum', status: false }
        ]
    );
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [accountType, setAccountType] = useState([
        { name: 'Guide', status: false },
        { name: 'Tourist', status: false },
        { name: 'Operator', status: false },
    ]);
    // const [warning, setWarning] = useState({
    //     email: '',
    //     password: ''
    // });
    // const handleWarning = (message) => {
    //     if (message.toUpperCase().includes('EMAIL')) {
    //         setWarning({ email: 'Incorrect email', password: '' });
    //     }
    //     if (message.toUpperCase().includes('EMAIL') && message.toUpperCase().includes('PASSWORD')) {
    //         setWarning({ email: 'Incorrect email', password: 'Incorrect password' });
    //     } else if (message.toUpperCase().includes('PASSWORD')) {
    //         setWarning({ email: '', password: 'Incorrect password' });
    //     }
    // };
    const handleFirstName = (e) => {
        const inComingText = e.target.value;
        setFirstName(inComingText);
    };
    const handleLastName = (e) => {
        const inComingText = e.target.value;
        setLastName(inComingText);
    };
    const handleEmail = (e) => {
        const inComingText = e.target.value;
        setEmail(inComingText);
    };
    const handlePhoneNum = (e) => {
        const inComingText = e.target.value;
        setPhoneNum(inComingText);
    };
    const handlePlaceholders = (name) => {
        const newPlaceHolderMover = [...placeHolderMover];
        newPlaceHolderMover.forEach((placeHolder) => {
            if (placeHolder['name'] === name) placeHolder['status'] = true;
            else placeHolder['status'] = false;
        });
        setPlaceHolderMover(newPlaceHolderMover);
    }
    const handleAccountType = (name) => {
        const newAccountType = [...accountType];
        newAccountType.forEach((account) => {
            if (account['name'] === name) account['status'] = true;
            else account['status'] = false;
        });
        setAccountType(newAccountType);
    };
    function escapeHtml(text) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return text.replace(/[&<>"']/g, function (m) { return map[m]; });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const api_key = process.env.REACT_APP_API_KEY;

        const user_type = accountType.filter(({ status }) => status)[0]['name'] !== 'Tourist' ? 'guide' : 'tourist';
        let guide_type = '';
        if (user_type === 'guide')
            guide_type = accountType.filter(({ status }) => status)[0]['name'] === 'Guide' ? 'certified_guide' : 'tour_operator';
        else guide_type = null;

        const request = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://www.polandwebdesigner.com/api/sign-up',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: {
                api_key: api_key,
                first_name: escapeHtml(firstName),
                last_name: escapeHtml(lastName),
                user_phone: escapeHtml(phoneNum),
                user_email: escapeHtml(email),
                user_type: escapeHtml(user_type),
                guide_type: guide_type !== null ? escapeHtml(guide_type) : null
            }
        });
        if (request.data['status'] === "success") {
            navigate(`/signup/${email}`);
        }
    }
    return (
        <div className="SignUp" >
            <h1>Register</h1>
            <span>Let's Create Your Account!</span>
            <span className="loginText">Already have an account?<NavLink to={'/login'} className={'link'}> Log In!</NavLink></span>
            <form onSubmit={handleSubmit}>
                <label htmlFor="firstName">
                    <input name="firstName" id="firstName" type="text" value={firstName} onChange={handleFirstName}
                        onClick={() => handlePlaceholders('firstName')}></input>
                    <span className="animatedPlaceHolder" style={
                        placeHolderMover.filter(({ name }) => name === 'firstName')[0]['status'] || firstName !== '' ?
                            { animation: "placeholderMoveUp 0.7s ease-in-out forwards" } :
                            { animation: "placeholderMoveDown 0.7s ease-in-out forwards" }}
                    >Your First Name</span>
                </label>
                <label htmlFor="lastName">
                    <input name="lastName" id="lastName" type="text" value={lastName} onChange={handleLastName}
                        onClick={() => handlePlaceholders('lastName')}></input>
                    <span className="animatedPlaceHolder" style={
                        placeHolderMover.filter(({ name }) => name === 'lastName')[0]['status'] || lastName !== '' ?
                            { animation: "placeholderMoveUp 0.7s ease-in-out forwards" } :
                            { animation: "placeholderMoveDown 0.7s ease-in-out forwards" }}>Your Last Name</span>
                </label>
                <label htmlFor="email">
                    <input name="email" id="email" type="email" value={email} onChange={handleEmail}
                        onClick={() => handlePlaceholders('email')} required></input>
                    <span className="animatedPlaceHolder" style={
                        placeHolderMover.filter(({ name }) => name === 'email')[0]['status'] || email !== '' ?
                            { animation: "placeholderMoveUp 0.7s ease-in-out forwards" } :
                            { animation: "placeholderMoveDown 0.7s ease-in-out forwards" }}>Your Email</span>
                </label>
                <label htmlFor="phoneNum">
                    <input name="phoneNum" id="phoneNum" type="text" value={phoneNum} onChange={handlePhoneNum}
                        onClick={() => handlePlaceholders('phoneNum')} ></input>
                    <span className="animatedPlaceHolder" style={
                        placeHolderMover.filter(({ name }) => name === 'phoneNum')[0]['status'] || phoneNum !== '' ?
                            { animation: "placeholderMoveUp 0.7s ease-in-out forwards" } :
                            { animation: "placeholderMoveDown 0.7s ease-in-out forwards" }}>Your Phone Number</span>
                </label>

                <div className="registerAsTitle">
                    <div className="line"></div>
                    <span className="animatedPlaceHolder">Register As:</span>
                    <div className="line"></div>
                </div>
                <div className="accountType">
                    <div className="type guide" onClick={() => handleAccountType('Guide')}
                        style={accountType.filter(({ name }) => name === 'Guide')[0]['status'] ? { backgroundColor: '#1967d2', color: 'white' } : {}}>Guide</div>
                    <div className="type tourist" onClick={() => handleAccountType('Tourist')}
                        style={accountType.filter(({ name }) => name === 'Tourist')[0]['status'] ? { backgroundColor: '#d93025', color: 'white' } : {}}>Tourist</div>
                    <div className="type guide" onClick={() => handleAccountType('Operator')}
                        style={accountType.filter(({ name }) => name === 'Operator')[0]['status'] ? { backgroundColor: '#1967d2', color: 'white' } : {}}>Operator</div>
                </div>
                <button className="register">Register<img src={diagonalRightArrowIconWhite} alt="diagonal arrow icon"></img></button>
            </form>
        </div>

    );
}

export default SignUp;