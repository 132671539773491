import QuickSearch from "../components/QuickSearch";
import WhyChooseTravelSwift from "../components/WhyTravelSwift";
import TrendingTours from "../components/TrendingTours";
import GuidePostIcome from "../components/GuidePostIcome";
import { useEffect } from "react";
function Home() {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <main>
            <QuickSearch />
            <TrendingTours />
            <WhyChooseTravelSwift />
            <GuidePostIcome />
        </main>
    );
}

export default Home;