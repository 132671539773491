import searchIcon from '../svg/searchIcon.svg';
import locationIcon from "../svg/locationIcon.svg";
import flagIcon from "../svg/flagIcon.svg";
import { useState, useEffect } from 'react';

function QuickSearch() {
    const [optionsClicked, setOptionClicked] = useState([
        { name: 'destination', status: false },
        { name: 'tourType', status: false }
    ]);
    const handleOptionsClicked = (name) => {
        const newOptionsClicked = [...optionsClicked];
        newOptionsClicked.forEach((option) => {
            if (option['name'] === name) option['status'] = !option['status'];
            else option['status'] = false;
        });
        setOptionClicked([...newOptionsClicked]);
    };
    //======== opition to choose from ======//
    const [destination, setDestination] = useState(
        {
            regions: [
                { name: 'Search Destination', type: '', status: true },
                { name: 'Siauliai', type: 'City', status: false },
                { name: 'Utena', type: 'City', status: false },
                { name: 'Vilnius', type: 'City', status: false },
                { name: 'Panevezys', type: 'City', status: false },
                { name: 'Mazeikiai', type: 'City', status: false },
                { name: 'Marijampole', type: 'City', status: false },
                { name: 'Klaipeda', type: 'City', status: false },
                { name: 'Kaunas', type: 'City', status: false },
                { name: 'Jonava', type: 'City', status: false }
            ]
        }
    );
    const handleDestination = (name) => {
        const newDestination = { ...destination };
        newDestination['regions'].forEach((region) => {
            if (region['name'] === name) region['status'] = true;
            else region['status'] = false;
        });
        setDestination({ ...newDestination });
    }
    const [tourType, setTourType] = useState([
        { name: 'All tour', status: true },
        { name: 'Hiking', status: false },
        { name: 'Food Tour', status: false },
        { name: 'Cultural Tours', status: false },
        { name: 'Museums Tours', status: false },
        { name: 'Beach Tours', status: false },
    ]);
    const handleTourType = (name) => {
        const newTourType = [...tourType];
        newTourType.forEach((tour) => {
            if (tour['name'] === name) tour['status'] = true;
            else tour['status'] = false;
        });
        setTourType([...newTourType]);
    }
    //======================================//
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        };

        // Add event listener to listen for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className="QuickSearch">
            {
                screenSize['width'] < 800 ? <>
                    <div className="QuickSearch-slogan">
                        <h1>Find the Best Guides in Lithuania!</h1>
                        <span>Discover the most incredible places to visit in Lithuania with the best
                            certified guide and walk buddy in the country.</span>
                    </div>
                    <div className="QuickSearch-engine">
                        <div
                            className="searchOption"
                            onClick={() => handleOptionsClicked('destination')}
                        >
                            <img src={locationIcon} alt="location icon"></img>
                            <div className="optionSelection"
                            >
                                <span className="searchName">Where</span>
                                {
                                    destination['regions'].map((region) => {
                                        if (region['status']) return (
                                            <span
                                                key={region['name']}
                                                className="choosenOption">{region['name']}
                                            </span>)
                                        else return '';
                                    }
                                    )
                                }
                            </div>
                            <div className="options" style={optionsClicked.filter(({ name }) => name === 'destination')[0]['status'] ? { display: 'block' } : { display: 'none' }}>
                                {
                                    destination['regions'].map((region) => {
                                        if (region['name'] !== 'Search Destination')
                                            return (
                                                <div
                                                    onClick={() => handleDestination(region['name'])}
                                                    key={region['name']}
                                                    className="option"
                                                ><span>{region['name']}</span><span className="type">{region['type']}</span></div>
                                            );
                                        else return '';

                                    }
                                    )
                                }
                            </div>
                        </div>
                        <div
                            onClick={() => handleOptionsClicked('tourType')}
                            className="searchOption"
                        >
                            <img src={flagIcon} alt="Flag icon"></img>
                            <div className="optionSelection">
                                <span className="searchName">Tour Type</span>
                                {
                                    tourType.map((tour) => {
                                        if (tour['status']) return (
                                            <span
                                                key={tour['name']}
                                                className="choosenOption">{tour['name']}
                                            </span>)
                                        else return '';
                                    }
                                    )
                                }
                            </div>
                            <div className="options" style={optionsClicked.filter(({ name }) => name === 'tourType')[0]['status'] ? { display: 'block' } : { display: 'none' }}>
                                {
                                    tourType.map((tour) => {
                                        if (tour['name'] !== 'All tour')
                                            return (
                                                <div
                                                    onClick={() => handleTourType(tour['name'])}
                                                    key={tour['name']}
                                                    className="option"
                                                ><span >{tour['name']}</span><span className="type">{tour['type']}</span></div>
                                            );
                                        else return '';

                                    }
                                    )
                                }
                            </div>
                        </div>
                        <button><img src={searchIcon} alt='search icon'></img></button>
                    </div>
                </> :
                    <>
                        <div className="QuickSearch-engine">
                            <div
                                className="searchOption"
                                onClick={() => handleOptionsClicked('destination')}
                            >
                                <img src={locationIcon} alt="location icon"></img>
                                <div className="optionSelection"
                                >
                                    <span className="searchName">Where</span>
                                    {
                                        destination['regions'].map((region) => {
                                            if (region['status']) return (
                                                <span
                                                    key={region['name']}
                                                    className="choosenOption">{region['name']}
                                                </span>)
                                            else return '';
                                        }
                                        )
                                    }
                                </div>
                                <div className="options" style={optionsClicked.filter(({ name }) => name === 'destination')[0]['status'] ? { display: 'block' } : { display: 'none' }}>
                                    {
                                        destination['regions'].map((region) => {
                                            if (region['name'] !== 'Search Destination')
                                                return (
                                                    <div
                                                        onClick={() => handleDestination(region['name'])}
                                                        key={region['name']}
                                                        className="option"
                                                    ><span>{region['name']}</span><span className="type">{region['type']}</span></div>
                                                );
                                            else return '';

                                        }
                                        )
                                    }
                                </div>
                            </div>
                            <div
                                onClick={() => handleOptionsClicked('tourType')}
                                className="searchOption"
                            >
                                <img src={flagIcon} alt="Flag icon"></img>
                                <div className="optionSelection">
                                    <span className="searchName">Tour Type</span>
                                    {
                                        tourType.map((tour) => {
                                            if (tour['status']) return (
                                                <span
                                                    key={tour['name']}
                                                    className="choosenOption">{tour['name']}
                                                </span>)
                                            else return '';
                                        }
                                        )
                                    }
                                </div>
                                <div className="options" style={optionsClicked.filter(({ name }) => name === 'tourType')[0]['status'] ? { display: 'block' } : { display: 'none' }}>
                                    {
                                        tourType.map((tour) => {
                                            if (tour['name'] !== 'All tour')
                                                return (
                                                    <div
                                                        onClick={() => handleTourType(tour['name'])}
                                                        key={tour['name']}
                                                        className="option"
                                                    ><span >{tour['name']}</span><span className="type">{tour['type']}</span></div>
                                                );
                                            else return '';

                                        }
                                        )
                                    }
                                </div>
                            </div>
                            <button><img src={searchIcon} alt='search icon'></img></button>
                        </div>
                        <div className="QuickSearch-slogan">
                            <h1>Find the Best Guides in Lithuania!</h1>
                            <span>Discover the most incredible places to visit in Lithuania with the best
                                certified guide and walk buddy in the country.</span>
                        </div>
                    </>
            }
        </div>
    );
}

export default QuickSearch;