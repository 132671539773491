import ticketIcon from "../svg/ticketIcon.svg";
import diamondIcon from "../svg/diamondIcon.svg";
import identificationUserIcon from "../svg/identificationUserIcon.svg";
import ballonIcon from "../svg/balloonIcon.svg";
function WhyChooseTravelSwift() {
    const facts = [
        {
            title: 'Ultimate flexibility',
            description: "You're in control, with free cancellation and payment options to satisfy any plan or budget.",
            img: ticketIcon
        },
        {
            title: 'Security',
            description: "Every guide is tracked during the journey. Identity verified.",
            img: identificationUserIcon
        },
        {
            title: 'Quality at our core',
            description: "High quality standards. Millions of reviews. A tourz company.",
            img: diamondIcon
        },
        {
            title: 'Memorable experiences',
            description: "Browse and book tours, guides and activities so incredible, you'll want to tell your friends.",
            img: ballonIcon
        }

    ]
    return (
        <div className="WhyChooseTravelSwift">
            <h2>About Travel Swift</h2>
            <div className="facts">
                {
                    facts.map((fact) =>
                        <div
                            key={fact['title']}
                            className="fact"
                        >
                            <img src={fact['img']} alt={`${fact['title']} icon`}></img>
                            <div>
                                <h3>{fact['title']}</h3>
                                <span>{fact['description']}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default WhyChooseTravelSwift;