import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function GeneralLayout() {
    const location = useLocation();
    const { pathname } = location;
   
    const [scrollTop, setScrollTop] = useState(pathname === '/' ? false : true);
    useEffect(() => {
        if (pathname !== '/') setScrollTop(true);
    }, [pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (pathname === '/') {
                if (window.scrollY > 0) setScrollTop(true);
                if (window.scrollY === 0) setScrollTop(false);
            }
        };
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname]);
    return (
        <div className="GeneralLayout" >
            <Header scrollTop={scrollTop} />

            <Outlet />
            <Footer />
        </div>
    );
}

export default GeneralLayout;