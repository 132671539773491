import { NavLink } from 'react-router-dom';


function Header({ scrollTop }) {

    return (
        <header className='header' style={
            scrollTop ? {} :
                { backgroundColor: 'transparent' }
        }  >
            <div className='header-left-side'>
                <h1>Travel Swift</h1>
                <nav>
                    <NavLink className="link" to={'/'}>Home</NavLink>
                    <NavLink className="link" to={'/'}>Tours</NavLink>
                    <NavLink className="link" to={'/'}>Destinations</NavLink>
                    <NavLink className="link" to={'/'}>Trips</NavLink>
                    <NavLink className="link" to={'/'}>Guides</NavLink>
                    <NavLink className="link" to={'/'}>Contact</NavLink>
                </nav>
            </div>
            <div className='header-right-side'>
                <NavLink to={'/signup'}><button className='signup-btn'>Sign up</button></NavLink>
                <NavLink to={'/login'}><button className='login-btn'>Login</button></NavLink>
            </div>
        </header>
    );
}

export default Header;