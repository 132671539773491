import diagonalRightArrowIconWhite from "../svg/diagonalRightArrowIconWhite.svg";
import passiveIcomeImage2 from "../img/passiveIcomeImage2.png";
import sideWhiteWave from "../svg/sideWhiteWave.svg";
import bottomWhiteWave from "../svg/bottomWhiteWave.svg";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

function GuidePostIcome() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
   
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="GuidePostIcome">
            <div className="GuidePostIcome-leftSide">
                <div className="GuidePostIcome-leftSide-alignement">
                    <h1>Feed our <span className="keyWords">solo-tourist ai</span> with travel posts and generate <span className="keyWords">extra income</span></h1>
                    <span>Option only available to guides, and Operator.</span>
                    <NavLink className={'link'} to={'/signup'}><button>Start Now<img src={diagonalRightArrowIconWhite} alt="arrow icon"></img></button></NavLink>
                </div>

            </div>

            <div className="GuidePostIcome-rightSide">
                <img
                    src={sideWhiteWave} alt="sideWave "
                    style={screenWidth < 812 ? { display: "none" } : {}}
                    className="sideWhiteWave"></img>
                <img
                    src={bottomWhiteWave} alt="BottomWave "
                    style={screenWidth < 812 ? {} : { display: "none" }}
                    className="bottomWhiteWave"></img>
                <img src={passiveIcomeImage2} alt="income  illustration" className="mainImage"></img>
            </div>
        </div>
    );
}

export default GuidePostIcome;