import { NavLink, useParams } from 'react-router-dom';
import { SuccessfullResponse } from './subComponents/loadingBtn/LoadingBtn.js';

function SignUpSuccess() {
    const { firstName } = useParams();
    return (
        <div className="SuccessRegistered">
            <SuccessfullResponse
                loading={true}
            />
            <div className='text'>
                <h3>Registration Successful</h3>
                <p><strong>{firstName}</strong>, your registration was successful check your email to confirm your email.</p>
            </div>
            <NavLink className='link' to={'/login'}>Go to Login</NavLink>
        </div>
    );
}

export default SignUpSuccess;