import './LoadingBtn.css';

export const LoadingRing = ({
    loading, btnColor = true
}) => {
    return (
        <div className={btnColor ? 'lds-dual-ring-green' : 'lds-dual-ring-red'}
            style={loading ?
                { 'display': 'block' } :
                { 'display': 'none' }}>
        </div>
    )
}


export const SuccessfullResponse = ({
    loading, btnColor = true }) => {
    return (
        <div className="wrapper" style={loading ?
            { 'display': 'block' } :
            { 'display': 'none' }}>
            <svg className={btnColor ? "checkmark-green" : "checkmark-red"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className={btnColor ? "checkmark__circle_green" : "checkmark__circle_red"} cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </div>
    )
}

export const ConfirmationBtn = ({
    loading, text, classname
}) => {
    return (
        <button className={classname} style={!loading['submit'] ? { 'display': 'none' } :
            {
                'backgroundColor': '#419E6A',
                'color': '#ffffff'
            }} >{text}
        </button>
    )
}
