import { NavLink } from "react-router-dom";
import facebookIcon from '../svg/facebookIcon.svg';
import instagramIcon from '../svg/instagramIcon.svg';
import linkedInIcon from '../svg/linkedInIcon.svg';
import xIcon from '../svg/xIcon.svg';
import amex from '../img/amex.png';
import applePay from '../img/applePay.png';
import discover from '../img/discover.png';
import paypal from '../img/paypal.png';
import visa from '../img/visa.png';
import masterCard from '../img/masterCard.png';
import headphoneIcon from "../svg/headphoneIcon.svg";
function Footer() {
    return (
        <footer className="footer">
            <div className="footer-upperSec">
                <div className="footer-upperSec-contacts">
                    <img src={headphoneIcon} alt="headphones"></img>
                    <span>Talk to Us Directly at +37061976407</span>
                </div>
                <div className="footer-upperSec-socialMedia">
                    <p>Follow us</p>
                    <NavLink><img src={facebookIcon} alt="social media icon"></img></NavLink>
                    <NavLink><img src={instagramIcon} alt="social media icon"></img></NavLink>
                    <NavLink><img src={linkedInIcon} alt="social media icon"></img></NavLink>
                    <NavLink><img src={xIcon} alt="social media icon"></img></NavLink>
                </div>
            </div>

            <div className="footer-middleSec">
                <nav className="contact">
                    <h3>Contact</h3>
                    <NavLink to={'/'} className="link">Siauliai, Lithuania.</NavLink>
                    <NavLink to={'/'} className="link">wispaztechnologies@gmail.com</NavLink>
                </nav>
                <nav>
                    <h3>Company</h3>
                    <NavLink to={'/'} className="link">About Us</NavLink>
                    <NavLink to={'/'} className="link">Tourz Reviews</NavLink>
                    <NavLink to={'/'} className="link">Contact Us</NavLink>
                    <NavLink to={'/'} className="link">Travel Guides</NavLink>
                    <NavLink to={'/'} className="link">Data Policy</NavLink>
                    <NavLink to={'/'} className="link">Cookie Policy</NavLink>
                </nav>
                <nav>
                    <h3>Support</h3>
                    <NavLink to={'/'} className="link">Get in Touch</NavLink>
                    <NavLink to={'/'} className="link">Help center</NavLink>
                    <NavLink to={'/'} className="link">Live chat</NavLink>
                    <NavLink to={'/'} className="link">How it works</NavLink>

                </nav>
                <nav>
                    <h3>Mobile Apps</h3>
                    <NavLink to={'/'} className="link">iOS App</NavLink>
                    <NavLink to={'/'} className="link">Android App</NavLink>
                </nav>
            </div>

            <div className="footer-bottomSec">
                <div className="footer-bottomSec-leftSec">
                    © Copyright Viatours 2023
                </div>
                <div className="footer-bottomSec-rightSec">
                    <NavLink to={'/'} className="link"><img src={masterCard} alt="payement method icon"></img></NavLink>
                    <NavLink to={'/'} className="link"><img src={visa} alt="payement method icon"></img></NavLink>
                    <NavLink to={'/'} className="link"><img src={applePay} alt="payement method icon"></img></NavLink>
                    <NavLink to={'/'} className="link"><img src={paypal} alt="payement method icon"></img></NavLink>
                    <NavLink to={'/'} className="link"><img src={discover} alt="payement method icon"></img></NavLink>
                    <NavLink to={'/'} className="link"><img src={amex} alt="payement method icon"></img></NavLink>
                </div>
            </div>
        </footer>
    );
}

export default Footer;