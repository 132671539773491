import { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
function Login() {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [accountType, setAccountType] = useState([
        { name: 'Guide', status: false },
        { name: 'Tourist', status: false },
        { name: 'Operator', status: false },
    ]);
    const [warning, setWarning] = useState({
        email: '',
        password: ''
    });
    const [placeHolderMover, setPlaceHolderMover] = useState(
        [
            { name: 'email', status: false },
            { name: 'password', status: false }
        ]
    );

    const handleEmail = (e) => {
        const inComingText = e.target.value;
        if (warning['email'] !== '') setWarning({ email: '', password: warning['password'] })
        setEmail(inComingText);
    };
    const handlePassword = (e) => {
        const inComingText = e.target.value;
        if (warning['password'] !== '') setWarning({ email: warning['email'], password: '' })
        setPassword(inComingText);
    }
    const handlePlaceholders = (name) => {
        const newPlaceHolderMover = [...placeHolderMover];
        newPlaceHolderMover.forEach((placeHolder) => {
            if (placeHolder['name'] === name) placeHolder['status'] = true;
            else placeHolder['status'] = false;
        });
        setPlaceHolderMover(newPlaceHolderMover);
    };
    const handleAccountType = (name) => {
        const newAccountType = [...accountType];
        newAccountType.forEach((account) => {
            if (account['name'] === name) account['status'] = true;
            else account['status'] = false;
        });
        setAccountType(newAccountType);
    };
    function escapeHtml(text) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return text.replace(/[&<>"']/g, function (m) { return map[m]; });
    };
    const handleWarning = (message) => {
        if (message.toUpperCase().includes('EMAIL')) {
            setWarning({ email: 'Incorrect email', password: '' });
        }
        if (message.toUpperCase().includes('EMAIL') && message.toUpperCase().includes('PASSWORD')) {
            setWarning({ email: 'Incorrect email', password: 'Incorrect password' });
        } else if (message.toUpperCase().includes('PASSWORD')) {
            setWarning({ email: '', password: 'Incorrect password' });
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const api_key = process.env.REACT_APP_API_KEY;
        const user_type = accountType.filter(({ status }) => status)[0]['name'] !== 'Tourist' ? 'guide' : 'tourist';
        let guide_type = '';
        if (user_type === 'guide')
            guide_type = 'guide';
        else guide_type = 'tourist';

        const request = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://www.polandwebdesigner.com/api/user-login',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: {
                api_key: api_key,
                user_email: escapeHtml(email),
                user_password: escapeHtml(password),
                user_type: guide_type !== null ? escapeHtml(guide_type) : null
            }
        });
        if (request.data['status'] === "success")
            navigate(`/personalArea`);
        else {
            if (request.data['status'] === "warning") {
                handleWarning(request.data['message']);
            }
        }
    };

    return (
        <div className="Login">
            <h1>Login</h1>
            <span className="signupText">Don't have an account?<NavLink to={'/signup'} className={'link'}> Sign Up!</NavLink></span>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">
                    <input name="email" id="email" type="email" value={email} onChange={handleEmail}
                        onClick={() => handlePlaceholders('email')} required></input>
                    <span className="animatedPlaceHolder" style={
                        placeHolderMover.filter(({ name }) => name === 'email')[0]['status'] || email !== '' ?
                            { animation: "placeholderMoveUp 0.7s ease-in-out forwards" } :
                            { animation: "placeholderMoveDown 0.7s ease-in-out forwards" }}
                    >Your Email</span>
                    {
                        warning['email'] !== '' ? <span className="error">{warning['email']}</span> : <></>
                    }
                </label>
                <label htmlFor="password">
                    <input name="password" id="password" type="password" value={password} onChange={handlePassword}
                        onClick={() => handlePlaceholders('password')} required></input>
                    <span className="animatedPlaceHolder" style={
                        placeHolderMover.filter(({ name }) => name === 'password')[0]['status'] || password !== '' ?
                            { animation: "placeholderMoveUp 0.7s ease-in-out forwards" } :
                            { animation: "placeholderMoveDown 0.7s ease-in-out forwards" }}>Your Password</span>
                    {
                        warning['password'] !== '' ? <span className="error">{warning['password']}</span> : <></>
                    }
                </label>
                <div className="loginAsTitle">
                    <div className="line"></div>
                    <span className="animatedPlaceHolder">Login As:</span>
                    <div className="line"></div>
                </div>
                <div className="accountType">
                    <div className="type guide" onClick={() => handleAccountType('Guide')}
                        style={accountType.filter(({ name }) => name === 'Guide')[0]['status'] ? { backgroundColor: '#1967d2', color: 'white' } : {}}>Guide</div>
                    <div className="type tourist" onClick={() => handleAccountType('Tourist')}
                        style={accountType.filter(({ name }) => name === 'Tourist')[0]['status'] ? { backgroundColor: '#d93025', color: 'white' } : {}}>Tourist</div>
                    <div className="type guide" onClick={() => handleAccountType('Operator')}
                        style={accountType.filter(({ name }) => name === 'Operator')[0]['status'] ? { backgroundColor: '#1967d2', color: 'white' } : {}}>Operator</div>
                </div>
                <button className="login">Login</button>
            </form>
        </div>
    );
}

export default Login;