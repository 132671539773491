import { useEffect } from "react";

function PersonalArea() {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return ( 
        <div className="PersonalArea">

        </div>
     );
}

export default PersonalArea;