import './App.css';
import {
  Route, RouterProvider,
  createBrowserRouter, createRoutesFromElements
} from 'react-router-dom';
import GeneralLayout from './layouts/GeneralLayout';
import Home from './home/Home';
import SignUp from './components/SignUp';
import SignUpSuccess from './components/SignUpSuccess';
import Login from './components/Login';
import PersonalArea from './components/PersonalArea';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/' element={<GeneralLayout />}>

        <Route index element={<Home />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='signup/:firstName' element={<SignUpSuccess />} />
        <Route path='login' element={<Login />} />

        <Route path='personalArea' element={<PersonalArea />}>
        </Route>

      </Route>
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
