import siauliai1 from '../img/siauliai1.jpg';
import utena1 from '../img/utena1.jpg';
import vilnius1 from '../img/vilnius1.jpg';
import panevezys1 from '../img/panevezys1.jpg';
import mazeikiai1 from '../img/mazeikiai1.jpg';
import marijampole1 from '../img/marijampole1.jpg';
import klaipeda1 from '../img/klaipeda1.jpg';
import jonava1 from '../img/jonava1.jpg';
import diagonalRightArrowIcon from '../svg/diagonalRightArrowIcon.svg';
import { NavLink } from 'react-router-dom';

function TrendingTours() {
    const destinations = [
        { title: 'Siauliai', toursNum: 100, img: siauliai1 },
        { title: 'Utena', toursNum: 300, img: utena1 },
        { title: 'Vilnius', toursNum: 400, img: vilnius1 },
        { title: 'Panevezy', toursNum: 100, img: panevezys1 },
        { title: 'Mazeikiai', toursNum: 600, img: mazeikiai1 },
        { title: 'Marijampole', toursNum: 200, img: marijampole1 },
        { title: 'Klaipeda', toursNum: 900, img: klaipeda1 },
        { title: 'Jonava', toursNum: 100, img: jonava1 },
    ];
    return (
        <div className="TrendingDestination">
            <div className='TrendingDestination-upperSec'>
                <h2>Trending Tours</h2>
                <NavLink className='link' to={'/'}>See all<img src={diagonalRightArrowIcon} alt='arrow icon'></img></NavLink>
            </div>

            <div className="destinations">
                {
                    destinations.map((destination, i) =>
                        <div
                            className="destination"
                            key={i.toString()}

                        >
                            <div style={{
                                width: '120px',
                                height: '120px',
                                backgroundImage: `url(${destination['img']})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: "cover",
                                borderRadius: '50%'
                            }}></div>
                            <div className='destinations-destination-title'>
                                <span className="title">{destination['title']}</span>
                                <span className='numTours'>{destination['toursNum']}+ tours</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default TrendingTours;